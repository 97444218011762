<template>
	<div class="page-subscription-success">
		<v-container>
			<div class="welcome">
				<h1>Welcome!</h1>
				<h5>In addition to being awesome you get:</h5>

				<!-- Premium -->
				<transition name="height">
					<ul v-if="currentPlan === 'premium' && isFounder === false">
						<li>High Quality Streaming</li>
						<li>10 Free downloads (per day)</li>
						<li>30 monthly suggestion votes</li>
						<li>Unlimited bookmarks</li>
						<li>Premium badge</li>
						<li>List view</li>
						<li>No Ads</li>
					</ul>
				</transition>


				<!-- Founder -->
				<transition name="height">
					<ul v-if="currentPlan === 'premium' && isFounder === true">
            <li>Lifetime Premium Membership</li>
            <li>High Quality Streaming</li>
            <li>20 Free downloads (per day)</li>
            <li>100 monthly suggestion votes</li>
            <li>Unlimited  bookmarks</li>
            <li>Founder badge</li>
            <li>List view</li>
            <li>No Ads</li>
					</ul>
				</transition>

				<router-link :to="{name: 'subscriptions'}" class="tetriatary_btn" :class="{show: showButton}">Let’s Go!</router-link>

			</div>
		</v-container>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import confetti from '@/mixins/confetti.js';

export default {
	name: "subscriptionSuccess",
	data: () => ({
		loader: false,
		currentPlan: '',
		isFounder: false,
		showButton: false,
	}),
	mixins: [confetti],
	created() {
		this.getProfile();
	},
	computed: {
		...mapGetters(['PROFILE'])
	},
	methods: {
		...mapActions(['GET_PROFILE']),

		getProfile() {
			this.loader = true;
			this.GET_PROFILE()
				.then(() => {
                    console.log('get profile', 'subscriptionSuccess');
					this.currentPlan = this.PROFILE.subscriptionPlan;
					this.isFounder = this.PROFILE.isFounder === true;
				})
				.catch(err => console.error(`subscription, GET_PROFILE', ${err}`))
				.finally(() => {
					setTimeout(() => {
						this.showButton = true;
					}, 400);
					this.loader = false;
				})
		},
	}
}
</script>

<style scoped>

</style>
